<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text label="평가명" name="assessmentName" v-model="searchParam.assessmentName" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="공종"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            :editable="editable"
            label="업체"
            name="assessVendorId"
            v-model="searchParam.assessVendorId">
          </c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="진행상태"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 조치업체 -->
            <!-- :disabled="vendorDisable" -->
          <c-vendor
            :plantCd="searchParam.plantCd"
            label="조치업체"
            name="actionDeptCd"
            v-model="searchParam.actionDeptCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="평가별 개선현황 목록"
      tableId="saasRiskAssess"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
      :merge="grid.merge"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template>
          <span>
            {{props.row.improveCompleteExpectDate}}
            <q-badge v-if="props.row.completeDateOver > 0" color="negative" label="지연" class="blinking" />
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-assess-list',
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentName: '',
        vendorCd: '',
        processCd: '',
        assessVendorId: '',
        ibmStepCd: null,
        actionDeptCd: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup1' },
          { index: 1, colName: 'mergeGroup1' },
          // { index: 6, colName: 'mergeGroup2' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '개선요청 제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '개선구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            label: '요청업체',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '개선대책',
            align: 'left',
            style: 'width:200px',
            type: 'html',
            sortable: true,
          },
          {
            name: 'improveCompleteExpectDate',
            field: 'improveCompleteExpectDate',
            label: '개선완료 예정일',
            align: 'center',
            style: 'width:120px',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '개선담당업체',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'assessVendorName',
            field: 'assessVendorName',
            label: '업체',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'assessItemName',
            field: 'assessItemName',
            label: '평가항목',
            align: 'left',
            style: 'width:350px',
            sortable: false,
          },
        ],
        data: [],
      },
      stepItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.$store.getters.user.innerFlag === 'N') { 
        this.searchParam.assessVendorId = this.$store.getters.user.deptCd;
      }
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // url setting
      this.listUrl = selectConfig.saas.resultStatus.vendorlist.url;
      // code setting
      this.$comm.getStepItems('RISK_ASSESS_STEP_CD').then(_result => {
        this.stepItems = _result;
      });
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // list setting
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col, index) {
      if (!row || row.ibmClassCd == 'IC00000001') {// 즉시조치
        this.popupOptions.title = '개선 상세'; // 개선 상세
        this.popupOptions.isFull = true;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.isFull = false;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
